import React, { useEffect } from 'react';

import {
  Alert,
  Box,
  CircularProgress,
  Snackbar,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ContentTileComponent } from '../../Components/ContentTitle';
import { PendingProjectsComponent } from '../../Components/PendingProjects';
import useGentian from '../../core/hookies/gentian.hook';
import { MapComponent } from '../../Components/Map';
import { Res } from '../../resources';
import { IProject } from '../../core/interfaces/project.interface';
import { featureCollection } from '@turf/turf';
import * as turf from '@turf/turf';
import { SharedResultsComponent } from '../../Components/SharedResults';

export const DashboardPage = () => {
  const { getProjects, updateProjectName } = useGentian();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: projects, isLoading, isFetched, refetch } = getProjects();
  const { data: sharedResults, isFetched: isFetchedSharedResults } = useGentian().getSharedResults();

  const [areas, setAreas] = React.useState<GeoJSON.FeatureCollection | undefined>(undefined);
  const [selectedArea, setSelectedArea] = React.useState<GeoJSON.FeatureCollection | undefined>(undefined);
  const [map, setMap] = React.useState<mapboxgl.Map | null>(null);
  const [activeTab, setActiveTab] = React.useState(0);
  const [isSaving, setIsSaving] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const [snackbarType, setSnackbarType] = React.useState<
    'success' | 'error' | 'warning' | 'info' | undefined
  >('success');

  useEffect(() => {
    if (isFetched && isFetchedSharedResults && !projects?.length && !sharedResults?.length) {
      window.location.href = '/survey-request';
    } else if (isFetched && projects?.length) {
      setAreas(featureCollection(projects.map((p) => p.AOI.features).flat()));
      setSelectedArea(projects.slice(-1)[0].AOI);
    }
    if (isFetched && isFetchedSharedResults && !projects?.length && sharedResults?.length) {
      setActiveTab(1);
    }
  }, [isFetched, isFetchedSharedResults]);

  useEffect(() => {
    setTimeout(() => {
      if (selectedArea && map) {
        map.fitBounds(turf.bbox(selectedArea) as any, { padding: 50, maxZoom: 17 });
      }
    }, 1000);
  }, [selectedArea, map]);

  const handleOnRowSelect = (project: IProject) => {
    setSelectedArea(project.AOI);
  };

  const handleProjectEdit = async (project: IProject) => {
    setIsSaving(true);

    await updateProjectName.mutate(
      { projectId: project._id, name: project.name },
      {
        onSuccess: async () => {
          await refetch();
          setIsSaving(false);
          setSnackbarMessage('Project name changed successfully');
          setSnackbarType('success');
          setSnackbarOpen(true);
        },
        onError: (error) => {
          setIsSaving(false);
          console.error(error);

          setSnackbarMessage((error as Error).message);
          setSnackbarType('error');
          setSnackbarOpen(true);
        },
      },
    );
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return isLoading || isSaving ? (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
      <Typography variant="body1">
        {isLoading ? `Loading project list...` : `Saving project name...`}
      </Typography>
    </Box>
  ) : (
    <Box>
      <ContentTileComponent className="hide-on-fullscreen">
        <Box sx={{ pl: 'var(--content-spacing-narrow)' }}>My Requests</Box>
      </ContentTileComponent>
      <Box
        sx={{
          flexDirection: 'column',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
        }}
      >
        {!isMobile && !!projects?.length && (
          <MapComponent
            accessToken={Res.components.maps.MAPBOX_TOKEN || ''}
            initialShape={areas}
            readOnly
            sx={{
              width: '80vw',
              height: '50vh',
            }}
            onLoad={(map) => {
              setMap(map);
            }}
          />
        )}
        <Box sx={{ width: '80vw' }}>
          <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)}>
            <Tab label="My Projects" />
            {!!sharedResults && <Tab label="Shared with me" />}
          </Tabs>
        </Box>
        <Box
          sx={{
            width: '80vw',
          }}
        >
          {activeTab === 0 &&
            (!!projects?.length ? (
              <PendingProjectsComponent
                projects={projects || []}
                onRowSelect={handleOnRowSelect}
                onEditProject={handleProjectEdit}
              />
            ) : (
              <Typography
                sx={{
                  width: '80vw',
                  margin: 4,
                }}
              >
                No Projects to display
              </Typography>
            ))}
          {activeTab === 1 && <SharedResultsComponent sharedResults={sharedResults || []} />}
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={snackbarType === 'success' ? 2000 : 6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarType} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
